export const pages = [
  { name: "About", link: "/about" },
  { name: "Team", link: "/team" },
  { name: "Projects", link: "/projects" },
  { name: "News", link: "/news" },
  { name: "Careers" },
  { name: "Contact", link: "/contact" },
  { name: "Terms & Conditions", link: "/terms-and-conditions" },
  { name: "Privacy Policy", link: "/privacy-policy" },
];
